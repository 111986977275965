



































import { Component, Vue } from "vue-property-decorator";
import DeerPrints from "@/components/decorations/DeerPrints.vue";

@Component({ components: { DeerPrints } })
export default class Welkom extends Vue {
  visibilityChanged(isVisible: boolean): void {
    this.$store.dispatch("setHeader", isVisible);
  }
}
