

























import { Component, Vue, Prop } from "vue-property-decorator";
import ArrangementSlide from "@/models/ArrangementSlide";
import Hammer from "hammerjs";

@Component
export default class Carousel extends Vue {
  private activeSlide = 0;

  @Prop({ required: true })
  slides!: ArrangementSlide[];

  get isPhone(): boolean {
    return window.matchMedia("(max-width: 500px)").matches;
  }

  created(): void {
    const root = document.querySelector(":root") as HTMLElement;
    const desktop = {
      length: this.slides.length * 900,
      gap: (this.slides.length - 1) * 3,
    };
    const phone = {
      length: this.slides.length * 300,
      gap: (this.slides.length - 1) * 3,
    };
    const width = this.isPhone
      ? `calc(${phone.length}px + ${phone.gap}rem)`
      : `calc(${desktop.length}px + ${desktop.gap}rem)`;
    root.style.setProperty("--slides-width", width);
  }

  mounted(): void {
    this.slides.forEach((s, i) => {
      const slide = this.$refs[`slide-${i}`] as Element[];
      const manager = new Hammer.Manager(slide[0], {
        recognizers: [
          [Hammer.Pan, { enable: false }],
          [Hammer.Pinch, { enable: false }],
          [Hammer.Press, { enable: false }],
          [Hammer.Rotate, { enable: false }],
          [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
          [Hammer.Tap, { enable: false }],
        ],
      });
      const swipe = new Hammer.Swipe();
      manager.add(swipe);
      manager.on("swipe", (e: { offsetDirection: number }) => {
        const direction = e.offsetDirection;
        if (direction === Hammer.DIRECTION_RIGHT) this.setActiveSlide(i - 1);
        else if (direction === Hammer.DIRECTION_LEFT)
          this.setActiveSlide(i + 1);
      });
    });
  }

  setActiveSlide(index: number): void {
    if (index < 0) index = 0;
    else if (index > this.slides.length - 1) index = this.slides.length - 1;
    this.activeSlide = index;
    this.animate();
  }

  animate(): void {
    const slides = this.$refs.slides as HTMLElement;
    const translate = this.isPhone
      ? `translateX(calc(${-this.activeSlide} * (300px + 3rem)))`
      : `translateX(calc(${-this.activeSlide} * (900px + 3rem)))`;
    slides.style.setProperty("transform", translate);
  }
}
