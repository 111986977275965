import Vue from "vue";
import Vuex, { Commit } from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { header: true },
  mutations: {
    SET_HEADER(state: { header: boolean }, status: boolean) {
      state.header = status;
    },
  },
  actions: {
    setHeader({ commit }: { commit: Commit }, status: boolean) {
      commit("SET_HEADER", status);
    },
  },
  getters: {
    getHeader: (state: { header: boolean }) => {
      return state.header;
    },
  },
});
