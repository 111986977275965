



















import { Component, Vue, Prop } from "vue-property-decorator";
import gsap from "gsap";

@Component
export default class SectionBreaker extends Vue {
  @Prop({ required: true })
  images!: string[];

  @Prop({ default: "left" })
  direction!: string;

  private tl = gsap.timeline();
  private frontImg = this.images[0];
  private backImg = this.images[0];
  private stage = 0;
  private frontIndex = 0;
  private backIndex = 1;

  constructor() {
    super();
    this.frontImg = this.images[0];
    this.backImg = this.images[0];
  }

  created(): void {
    gsap.defaults({ ease: "ease", duration: 1 });
    this.frontImg = this.images[0];
    this.backImg = this.images[0];
  }

  mounted(): void {
    if (this.images.length > 1) {
      this.fadeImages();
      setInterval(this.fadeImages, 2000);
    }
  }

  fadeImages(): void {
    const frontImage = this.$refs.front_image as HTMLElement;
    switch (this.stage) {
      case 0:
        this.tl.to(frontImage, {
          opacity: 0,
        });
        break;
      case 1:
        this.frontImg = this.images[this.frontIndex];
        this.frontIndex += 2;
        if (this.frontIndex > this.images.length - 1)
          this.frontIndex -= this.images.length;
        break;
      case 2:
        this.tl.to(frontImage, {
          opacity: 1,
        });
        break;
      case 3:
        this.backImg = this.images[this.backIndex];
        this.backIndex += 2;
        if (this.backIndex > this.images.length - 1)
          this.backIndex -= this.images.length;
        break;
    }
    this.stage += 1;
    if (this.stage > 3) this.stage = 0;
  }
}
