















































import { Component, Vue } from "vue-property-decorator";
import Contact from "@/components/sections/Contact.vue";

@Component({ components: { Contact } })
export default class BedAndBreakfast extends Vue {
  private cards = [
    {
      img: "/images/bedAndBreakfast/bed.jpg",
    },
    {
      img: "/images/bedAndBreakfast/bed_1.jpg",
    },
    {
      img: "/images/bedAndBreakfast/sitting_area.jpg",
    },
    {
      img: "/images/bedAndBreakfast/door_balcony.jpg",
    },
    {
      img: "/images/bedAndBreakfast/bathroom.jpeg",
    },
    {
      img: "/images/bedAndBreakfast/toilet.jpg",
    },
    {
      img: "/images/bedAndBreakfast/grass.jpg",
    },
    {
      img: "/images/bedAndBreakfast/parking.jpg",
    },
    {
      img: "/images/bedAndBreakfast/balcony.jpg",
    },
  ];
}
