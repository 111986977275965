



















import { Component, Vue } from "vue-property-decorator";
import Welkom from "@/components/sections/Welkom.vue";
import HighTea from "@/components/sections/High-Tea.vue";
import Arrangementen from "@/components/sections/Arrangementen.vue";
import Schilderijen from "@/components/sections/Schilderijen.vue";
import OpeningsTijden from "@/components/sections/OpeningsTijden.vue";
import Contact from "@/components/sections/Contact.vue";
import SectionBreaker from "@/components/decorations/SectionBreaker.vue";

@Component({
  components: {
    Welkom,
    HighTea,
    Arrangementen,
    Schilderijen,
    OpeningsTijden,
    Contact,
    SectionBreaker,
  },
})
export default class Home extends Vue {}
