












import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Card extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  body!: string;
}
