

















































import { Component, Vue } from "vue-property-decorator";
import OpeningHours from "../../models/OpeningHours";

@Component
export default class Contact extends Vue {
  get summer(): boolean {
    const month = new Date().getMonth();
    return month >= 4 && month < 9;
  }

  getOpeningHours(): OpeningHours {
    const hours: OpeningHours = {
      summer: {
        Maandag: "Op afspraak",
        Dinsdag: "Op afspraak",
        Woensdag: "10:00 - 17:00",
        Donderdag: "10:00 - 17:00",
        Vrijdag: "10:00 - 17:00",
        Zaterdag: "10:00 - 17:00",
        Zondag: "10:00 - 17:00",
      },
      winter: {
        Maandag: "Op afspraak",
        Dinsdag: "Op afspraak",
        Woensdag: "Op afspraak",
        Donderdag: "Op afspraak",
        Vrijdag: "Op afspraak",
        Zaterdag: "Op afspraak",
        Zondag: "10:00 - 17:00",
      },
    };

    return hours;
  }
}
