































import { Component, Vue } from "vue-property-decorator";
import Logo from "@/components/Logo.vue";
import Navigation from "@/components/Navigation.vue";

@Component({ components: { Logo, Navigation } })
export default class Header extends Vue {
  private drawerActive = false;

  get header(): boolean {
    return this.$store.getters.getHeader;
  }
}
