























































































































import { Component, Vue } from "vue-property-decorator";
import gsap from "gsap";

@Component
export default class DeerPrints extends Vue {
  private tl = gsap.timeline();

  created(): void {
    gsap.defaults({ ease: "ease", duration: 0.5, delay: 0.2 });
  }

  mounted(): void {
    this.walkDeer();
    setInterval(this.walkDeer, 6 * 1000);
  }

  walkDeer(): void {
    const prints = gsap.utils.toArray(".deer-print");
    for (let i = 1; i <= prints.length; i++) {
      this.tl.to(`#deer_print_${i}`, {
        opacity: 1,
      });
    }
    for (let i = 1; i <= prints.length; i++) {
      this.tl.to(
        `#deer_print_${i}`,
        {
          opacity: 0,
          delay: i === 1 ? 10 : 0.7,
          duration: 3,
        },
        "<"
      );
    }
  }
}
