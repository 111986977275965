













import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Logo extends Vue {
  @Prop({ default: false })
  isDrawerLogo!: boolean;
}
