





















































import { Component, Vue } from "vue-property-decorator";
import Carousel from "@/components/Carousel.vue";
import Card from "@/components/Card.vue";

@Component({ components: { Card, Carousel } })
export default class Arrangementen extends Vue {
  cards = [
    {
      title: "Maak uw eigen badgel",
      body: "Er wordt uitgelegd wat de speciale werking is van de kruiden en de etherische olie voor huid en je gemoed. Kosten €12,50 incl. een hapje en een drankje. In combinatie met een mini-hightea bestaande uit een zoete en hartige hap (en uiteraard thee of koffie) Kosten €22,50.",
    },
    {
      title: "Stel uw eigen haarshampoo samen",
      body: "Er wordt uitgelegd wat de speciale werking is van de kruiden en de etherische olie voor je haar en je gemoed. Kosten €12,50 incl. een hapje en een drankje. In combinatie met een mini-hightea bestaande uit een zoete en hartige hap (en uiteraard thee of koffie) Kosten €22,50.",
    },
    {
      title: "Stel uw eigen gezichtscrème samen",
      body: "Er wordt uitgelegd wat de speciale werking is van de kruiden en de etherische olie voor je haar en je gemoed. Kosten €12,50 incl. een hapje en een drankje. In combinatie met een mini-hightea bestaande uit een zoete en hartige hap (en uiteraard thee of koffie) Kosten €22,50.",
    },
    {
      title: "Najaars arrangement",
      body: `<ul>
            <li>
              Het maken van een kruidenbittertje / kruidenlikeurtje heerlijk te
              nuttigen bij de kachel als het weer guur wordt, (anti-hoest,
              anti-viraal of gewoon lekker).
            </li>
            <li>Het vervaardigen van mosterd.</li>
            <li>
              Vervaardigen van uw eigen gezichtscreme om het koude weer te
              trotseren.
            </li>
          </ul>

          <p>
            Deze workshop is bedoeld om een uitgebreidere kijk te kijken op de
            mogenlijkheden van kruiden. Namenlijk schoonheid, culinair, lekker
            en met een knipoog naar de medicinale werking. <br /><br />
            Deze workshop kost €25 inclusief een hapje en een drankje. <br />
            In combinatie met een high-tea kost deze workshop €40. <br />
            In combinatie met een mini-hightea €32,50. <br /><br />`,
    },
  ];
}
